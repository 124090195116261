import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const completeDeposit=(id)=>async dispatch=>{
    dispatch({
        type:actionTypes.COMPLETE_DEPOSIT_START
    });
    const options={
        url:`/deposits/${id}/complete`,
        method:'POST'
    }
    await axios(options)
                .then(response=>dispatch({
                    type:actionTypes.COMPLETE_DEPOSIT_SUCCESS,
                    payload:response.data
                }))
                .catch(error=>dispatch({
                    type:actionTypes.COMPLETE_DEPOSIT_FAIL,
                    payload:getMessage(error)
                }))
    
}