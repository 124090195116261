import React from 'react';
import { Row, Col, Table, PageHeader, Button, Space, message, Modal } from 'antd';
import {
   PlusOutlined,
   EditOutlined,
   DeleteOutlined,
   ExclamationCircleOutlined,
} from '@ant-design/icons';
import { getPayments } from '../../redux/actions/payment/getPayments';
import { getPaymentTypes } from '../../redux/actions/payment/getPaymentTypes';
import { connect } from 'react-redux';
import {
   toggleAddPaymentModal,
   toggleUpdatePaymentModal
} from '../../redux/actions/payment/toggleModal';
import { deletePayment } from '../../redux/actions/payment/deletePayment';

class Payment extends React.Component {
   async componentDidMount() {
      await this.props.onGetPayments();
      await this.props.onGetPaymentTypes();
   }
   handleDelete = async (id) => {
      await this.props.onDelete(id);
      if (this.props.message) message.success(this.props.message);
      if (this.props.error) message.error(this.props.error);
   }
   showDeleteConfirm = (id) => {
      const that = this;
      Modal.confirm({
         title: 'Are you sure delete this payment?',
         icon: <ExclamationCircleOutlined />,
         content: `Payment id is ${id}`,
         okText: 'Yes',
         okType: 'danger',
         cancelText: 'No',
         onOk() {
            that.handleDelete(id);
         },
      });
   }
   columns = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id'
      },
      {
         title: 'Name',
         dataIndex: 'name',
         key: 'name'
      },
      {
         title: 'AccountNo',
         dataIndex: 'accountNo',
         key: 'accountNo',
      },
      {
         title: 'Type',
         dataIndex: 'payment',
         key: 'payment',
         render: (text, record) => record?.payment?.name
      },
      {
         title: 'Image',
         key: 'image',
         render: (text, record) => <img
            width="50px"
            key={`img${record?.payment?.id}`}
            src={record?.payment?.imageUrl} />
      },
      {
         title: 'Action',
         key: 'action',
         render: (text, record) => <Space size="middle">
            <EditOutlined onClick={() => this.props.onEdit(record)} />
            <DeleteOutlined onClick={() => this.showDeleteConfirm(record?.id)} />
         </Space>
      }
   ]

   render() {
      return (
         <>
            <Row>
               <Col span={24}>
                  <PageHeader
                     style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                     }}
                     title={<h5>Payments</h5>}
                  >
                     <Button onClick={this.props.onOpenAddModal} icon={<PlusOutlined />}>New</Button>
                  </PageHeader>
               </Col>
               <Col span={24} style={{
                  width: '100%',
                  overflowX: 'scroll',
                  scrollbarWidth: 'none'
               }}>
                  <Table
                     pagination={false}
                     key="p-table"
                     dataSource={this.props.payments}
                     columns={this.columns}
                     loading={this.props.loading} />
               </Col>
            </Row>
         </>
      );
   }
}

const mapStateToProps = state => ({
   payments: state.payment.all,
   error: state.payment.error,
   message: state.payment.message,
   paymentTypes: state.payment.paymentTypes,
   loading: state.payment.loading
});

const mapDispatchToProps = dispatch => ({
   onGetPayments: () => dispatch(getPayments()),
   onOpenAddModal: () => dispatch(toggleAddPaymentModal()),
   onGetPaymentTypes: () => dispatch(getPaymentTypes()),
   onEdit: (payment) => dispatch(toggleUpdatePaymentModal(payment)),
   onDelete: (id) => dispatch(deletePayment(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);