import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const saveFCMToken = (fcmToken) => async dispatch => {

   console.log([fcmToken])
   dispatch({
      type: actionTypes.SAVE_FCM_TOKEN_START
   });
   const options = {
      url: '/fcmTokens/store',
      method: 'POST',
      data: {
         fcmToken: fcmToken
      }
   }
   await axios(options)
      .then(response => {
         console.log('responseOnFCM', response)
         dispatch({
            type: actionTypes.SAVE_FCM_TOKEN_SUCCESS,
         })
      })
      .catch(error => {
         console.log('errorOnFCM',error);
         dispatch({
            type: actionTypes.SAVE_FCM_TOKEN_FAIL,
            payload: getMessage(error)
         })
      })
}