import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, message, Input, Select } from 'antd';
import { toggleUpdatePaymentModal } from '../../redux/actions/payment/toggleModal';
import { updatePayment } from '../../redux/actions/payment/updatePayment';
class UpdatePaymentModal extends React.Component {
   constructor(props) {
      super(props);
      this.form = React.createRef();
   }
   layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
   };
   onFinish = async (values) => {
      const request = {
         id: this.props.paymentToUpdate?.id,
         name: values.name,
         accountNo: values.accountNo,
         paymentId: +values.paymentId
      };
      await this.props.onUpdate(request);
      if (this.props.message) {
         message.success(this.props.message);
         this.form.current.resetFields();
         this.props.onClose();
      }
      if (this.props.error) {
         message.error(this.props.error)
      }
   }
   onFinishFailed = (errors) => {
      console.log(errors)
   }
   handleCancel = () => {
      this.props.onClose();
   }
   render() {
      let options = this.props.paymentTypes?.map(payment => {
         return <Select.Option
            key={payment?.id}
            value={`${payment?.id}`}>
            {payment?.name}
         </Select.Option>;
      })
      return (
         <Modal
            title="Update Payment"
            key={"update-payment-modal"}
            centered
            visible={this.props.visible}
            okButtonProps={{ form: 'update-payment-form', key: 'submit', htmlType: 'submit' }}
            confirmLoading={this.props.loading}
            onCancel={this.handleCancel}
            okText="Save"
            loading={this.props.loading}
         >
            <Form
               {...this.layout}
               ref={this.form}
               style={{ margin: 25 }}
               id="update-payment-form"
               name="basic"
               onFinish={this.onFinish}
               onFinishFailed={this.onFinishFailed}
               initialValues={{
                  name: this.props.paymentToUpdate?.name,
                  accountNo: this.props.paymentToUpdate?.accountNo,
                  paymentId: `${this.props.paymentToUpdate?.payment?.id}`,
               }}
            >
               <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                     {
                        required: true,
                        message: 'Require!',
                     }
                  ]}
               >
                  <Input />
               </Form.Item>
               <Form.Item
                  name="accountNo"
                  label="AccountNo"
                  rules={[
                     {
                        required: true,
                        message: 'Require!',
                     }
                  ]}
               >
                  <Input />
               </Form.Item>
               <Form.Item
                  name="paymentId"
                  label="Payment Type"
                  rules={
                     [{
                        required: true,
                        message: 'Require payment type',
                     }]
                  }>
                  <Select style={{ width: 180 }}>
                     {options}
                  </Select>
               </Form.Item>
            </Form>
         </Modal>
      );
   }
}

const mapStateToProps = state => ({
   visible: state.payment.updateVisible,
   loading: state.payment.loading,
   error: state.payment.error,
   message: state.payment.message,
   paymentTypes: state.payment.paymentTypes,
   paymentToUpdate: state.payment.paymentToUpdate
})

const mapDispatchToProps = dispatch => ({
   onUpdate: async (payment) => await dispatch(updatePayment(payment)),
   onClose: () => dispatch(toggleUpdatePaymentModal(null)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePaymentModal);