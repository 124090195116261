import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const getPaymentTypes=()=>async dispatch=>{
    dispatch({
        type:actionTypes.GET_PAYMENT_TYPE_START
    });
    await axios.get('/payments')
            .then(response=>dispatch({
                type:actionTypes.GET_PAYMENT_TYPE_SUCCESS,
                payload:response.data
            }))
            .catch(error=>dispatch({
                type:actionTypes.GET_PAYMENT_TYPE_FAIL,
                payload:getMessage(error)
            }))
}