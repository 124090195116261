import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const deletePayment = (id) => async dispatch => {
   dispatch({
      type: actionTypes.DELETE_PAYMENT_START
   });
   await axios.delete(`/agentPayments/${id}`)
      .then(response => {
         dispatch({
            type: actionTypes.DELETE_PAYMENT_SUCCESS,
            payload: { id }
         });
      })
      .catch(error => dispatch({
         type: actionTypes.DELETE_PAYMENT_FAIL,
         payload: getMessage(error)
      }))
}