import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';
import {updateUserBalance} from '../user/updateUserBalance';
import {updateMemberBalance} from '../member/updateMemberBalance';

export const createTransaction=({
    memberId,
    command,
    amount,
    credit
})=>async dispatch=>{
    dispatch({
        type:actionTypes.CREATE_TRANSACTION_START
    })
    const options={
        url:`/transactions/members/${memberId}`,
        method:'POST',
        data:{
            command,
            amount,
            credit
        }
    }
    await axios(options)
            .then(response=>{
               dispatch({
                  type:actionTypes.CREATE_TRANSACTION_SUCCESS,
                  payload:response.data
              });
              dispatch(updateMemberBalance(response.data));
              dispatch(updateUserBalance(response.data));
            })
            .catch(error=>dispatch({
                type:actionTypes.CREATE_TRANSACTION_FAIL,
                payload:getMessage(error)
            }))
}