import firebase from 'firebase/app';
import 'firebase/messaging';

const config = {
   apiKey: "AIzaSyDgBlpQIcMJ2JyumHxXeLu3ZdJ4jZJgC2s",
   authDomain: "mix-a7d72.firebaseapp.com",
   projectId: "mix-a7d72",
   storageBucket: "mix-a7d72.appspot.com",
   messagingSenderId: "7826456347",
   appId: "1:7826456347:web:7924475fee3a1e29d29dbe",
   measurementId: "G-C2SQ7YLFSZ"
};

let firebaseMessaging = null;

if (firebase.messaging.isSupported()) {
   firebase.initializeApp(config);
   firebaseMessaging = firebase.messaging();
}

export { firebaseMessaging };

export const requestFirebaseNotificationPermission = () =>
   new Promise((resolve, reject) => {
      Notification
         .requestPermission()
         .then(() => firebaseMessaging.getToken())
         .then((firebaseToken) => {
            resolve(firebaseToken);
         })
         .catch((err) => {
            reject(err);
         });
   });