export const getMessage = (error) => {
   console.error(error)
   if (!error.response) {
      return `Network error`
   }
   if(error.response.data){
      const { message, error:errorMessage } = error.response.data;
      return message || errorMessage;
   }
   switch (error.response.status) {
      case 400:
         return error.response?.data?.message || "Process failed";
      case 401:
         return `401 unauthorized`;
      case 402:
         return `402 payment required`;
      case 403:
         return `403 Forbidden`;
      case 404:
         return `404 Not Found`;
      case 405:
         return `405 Method Not Allowed`;
      case 406:
         return `406 Not Acceptable`;
      case 407:
         return `407 Proxy Authentication Required`;
      case 408:
         return `408 Request Timeout`;
      case 500:
         return `500 Internal Server Error`;
      case 501:
         return `501 Not Supported`;
      case 502:
         return `502 Bad Gateway`;
      case 503:
         return `503 Service Unavailable`;
      case 504:
         return `504 Gateway Timeout`;
      default:
         return `Unknown error occurred. Contact to the development team`;
   }
}