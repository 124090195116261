import React from 'react';
import { Row, Col, Table, message, PageHeader, Card, Button, Switch } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getOneMember } from '../../redux/actions/member/getOneMember';
import * as actionTypes from '../../redux/actions/member/actionTypes';
import { setCommissionToUpdate } from '../../redux/actions/member/setCommissionToUpdate';
import { toggleTransactionAddModal } from '../../redux/actions/transaction/toggleTransactionAddModal';
import { updateSuspend } from '../../redux/actions/member/updateSuspend';
import { updateTwoThreeBet } from '../../redux/actions/member/updateMember';
import {toggle4DCommissionUpdate} from '../../redux/actions/member/toggle4DCommissionUpdate';
import {
      EditOutlined,
      TransactionOutlined,
      LockOutlined,
      UnlockOutlined,
      RetweetOutlined
} from '@ant-design/icons';

class MemberDetail extends React.Component {
      state = {
            isMobile: false,
      }
      async componentDidMount() {
            const query = window.matchMedia(`(max-width: 480px)`);
            query.onchange = (e) => {
                  this.setState(prevState => {
                        return {
                              ...prevState,
                              isMobile: e.matches
                        }
                  })
            }
            this.setState({
                  isMobile: query.matches
            })
            const { match: { params } } = this.props;
            await this.props.onGetMember(params.id);
            if (this.props.error) {
                  message.error(this.props.error);
            }
      }

      addCommaToNumber = (num) => `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      updateCommission = async (record) => {
            await this.props.onSetCommission(record);
            await this.props.onOpenCommissionUpdate();
      }
      handleSuspend = async () => {
            await this.props.onUpdateSuspend({
                  id: this.props.member?.id,
                  suspend: !this.props.member?.suspended
            });
            if (this.props.message) {
                  message.success(this.props.message);
            }
      }
      render() {
            const columns = [
                  {
                        title: 'MatchCount',
                        dataIndex: 'matchCount',
                        key: 'matchCount'
                  },
                  {
                        title: 'Tax',
                        dataIndex: 'winCommission',
                        key: 'winCommission'
                  },
                  {
                        title: 'Commission',
                        dataIndex: 'betCommission',
                        key: 'betCommission'
                  },

                  {
                        title: 'Action',
                        render: (text, record) => (<EditOutlined onClick={
                              () => this.updateCommission(record)
                        } />)
                  }
            ];
            const {
                  name,
                  username,
                  mobile,
                  balance,
                  // twoDCommission,
                  threeDCommission,
                  // fourDCommission,
                  twoThreeAllowed,
                  betLimitation: {
                        maxForSingleBet,
                        maxForMixBet,
                  },
                  singleBetCommission: {
                        betCommission,
                        highBetCommission,
                        winCommission,
                        highWinCommission
                  }
            } = this.props.member;
            const { isMobile } = this.state;
            const BUTTONS = {
                  marginTop: 16,
                  display: 'flex',
                  flexDirection: isMobile ? "column" : "row",
                  gap: 16
            }
            return (<>
                  <Row gutter={[16, 16]}>
                        <Col span={24}>
                              <Card
                                    type="inner"
                                    title={<div style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                          width: '100%'
                                    }}>
                                          <h3>Basic Info</h3>
                                          <EditOutlined onClick={() => this.props.onOpenBasicUpdate()} />
                                    </div>}
                                    loading={this.props.loading}
                              >
                                    <div>Name - {name}</div>
                                    <div>Username - {username}</div>
                                    <div>Mobile - {mobile}</div>
                                    <div>Balance - {this.addCommaToNumber(balance)} </div>
                                    <div style={BUTTONS}>
                                          <Button style={{
                                                backgroundColor: '#8d99ae',
                                                color: 'white',
                                          }}
                                                onClick={this.props.onOpenPaswordResetModal}
                                          >
                                                <RetweetOutlined /> Reset Password
                                          </Button>
                                          <Button style={{
                                                backgroundColor: '#e5383b',
                                                color: 'white',
                                          }}
                                                onClick={this.props.onOpenTransactionAdd}
                                          >
                                                <TransactionOutlined /> Manage Balance
                                          </Button>
                                          <Button style={{
                                                backgroundColor: this.props.member?.suspended ?
                                                      '#e5383b' : '#2dc653',
                                                color: 'white'
                                          }}
                                                onClick={this.handleSuspend}
                                                loading={this.props.loading}
                                          >
                                                {this.props.member?.suspended ?
                                                      <LockOutlined /> : <UnlockOutlined />}
                                                {this.props.member?.suspended ? 'Unsuspend' : ' Suspend'}
                                          </Button>
                                    </div>
                              </Card>
                        </Col>
                        <Col span={24}>
                              <Card
                                    type="inner"
                                    title={<div style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                          width: '100%'
                                    }}>
                                          <h3>Bet Limitations</h3>
                                          <EditOutlined onClick={() => this.props.onOpenBetLimit()} />
                                    </div>}
                                    loading={this.props.loading}
                              >
                                    <div style={FINANCE_LIMIT_STYLE}>
                                          <div style={LIMIT_STYLE}>Max For Mix Bet </div>
                                          <div style={{ color: 'blue', ...LIMIT_STYLE }}>
                                                {this.addCommaToNumber(maxForMixBet)}</div>
                                          <div style={LIMIT_STYLE}>Max For Single Bet </div>
                                          <div style={{ color: 'blue', ...LIMIT_STYLE }}>
                                                {this.addCommaToNumber(maxForSingleBet)}</div>
                                    </div>
                              </Card>
                        </Col>
                        <Col span={24}>
                              <Card
                                    type="inner"
                                    title={<div style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                          width: '100%'
                                    }}>
                                          <h3>Single Bet Commission</h3>
                                          <EditOutlined onClick={() => this.props.onOpenSingleBetCommission()} />
                                    </div>}
                                    loading={this.props.loading}
                              >
                                    <div style={FINANCE_LIMIT_STYLE}>
                                          <div style={LIMIT_STYLE}>Commission</div>
                                          <div style={{ color: 'blue', ...LIMIT_STYLE }}>
                                                {this.addCommaToNumber(betCommission)}
                                          </div>
                                          <div style={LIMIT_STYLE}>High Commission </div>
                                          <div style={{ color: 'blue', ...LIMIT_STYLE }}>
                                                {this.addCommaToNumber(highBetCommission)}
                                          </div>
                                    </div>
                                    <div style={FINANCE_LIMIT_STYLE}>
                                          <div style={LIMIT_STYLE}>Tax</div>
                                          <div style={{ color: 'blue', ...LIMIT_STYLE }}>
                                                {this.addCommaToNumber(winCommission)}
                                          </div>
                                          <div style={LIMIT_STYLE}>High Tax </div>
                                          <div style={{ color: 'blue', ...LIMIT_STYLE }}>
                                                {this.addCommaToNumber(highWinCommission)}
                                          </div>
                                    </div>
                              </Card>
                        </Col>
                        {this.props.currentUser?.twoThreeAllowed ? (
                              <Col span={24}>
                                    <Card
                                          type="inner"
                                          title={<div style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                width: '100%'
                                          }}>
                                                <h3>Three D Commission</h3>
                                          </div>}
                                          loading={this.props.loading}
                                    >
                                          
                                          <div>ThreeD Commission - {threeDCommission} &nbsp; &nbsp;<EditOutlined
                                                onClick={
                                                      () => this.props.onOpen3DCommissionUpdate()
                                                }
                                          /></div>
                                          {/* <div>FourD Commission - {fourDCommission} &nbsp; &nbsp;<EditOutlined
                                                onClick={
                                                      () => this.props.onOpen4DCommissionUpdate()
                                                }
                                          /></div> */}
                                    </Card>
                              </Col>
                        ) : null}
                        <PageHeader title={<h5>Mix Bet Commissions</h5>} />
                        <Col span={24} style={{
                              width: '100%',
                              overflowX: 'scroll',
                        }}>
                              <Table columns={columns}
                                    dataSource={this.props.member?.commissions}
                                    loading={this.props.loading} />
                        </Col>
                  </Row>
            </>)
      }
}

const mapStateToProps = state => ({
      loading: state.member.loading,
      member: state.member.one,
      error: state.member.error,
      message: state.member.message,
      currentUser: state.user.current
})

const mapDispatchToProps = dispatch => ({
      onGetMember: (id) => dispatch(getOneMember(id)),
      onOpenBasicUpdate: () => dispatch({
            type: actionTypes.TOGGLE_BASIC_INFO_UPDATE
      }),
      onOpenSingleBetCommission: () => dispatch({
            type: actionTypes.TOGGLE_SINGLE_BET_COMMISSION_UPDATE
      }),
      onOpenBetLimit: () => dispatch({
            type: actionTypes.TOGGLE_BET_LIMIT_UPDATE
      }),
      onOpenCommissionUpdate: () => dispatch({
            type: actionTypes.TOGGLE_COMMISSION_UPDATE
      }),
      onSetCommission: (commission) => dispatch(setCommissionToUpdate(commission)),
      onOpenTransactionAdd: () => dispatch(toggleTransactionAddModal()),
      onUpdateSuspend: (obj) => dispatch(updateSuspend(obj)),
      onOpenPaswordResetModal: () => dispatch({
            type: actionTypes.TOGGLE_RESET_PASSWORD_MODAL
      }),
      onOpen4DCommissionUpdate: () => dispatch(toggle4DCommissionUpdate()),
      onOpen3DCommissionUpdate: () => dispatch({
            type: actionTypes.TOGGLE_3D_COMMISSION_UPDATE
      }),
      onUpdateTwoThreeBet: (id, twoThreeBet) => dispatch(updateTwoThreeBet(id, twoThreeBet))
})

const FINANCE_LIMIT_STYLE = {
      display: 'flex',
      flexDirection: 'row',
      gap: 10
}

const LIMIT_STYLE = {
      width: '33%'
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberDetail));