import React, { Component } from 'react';
import { Form, Input, Button, Checkbox, Row, Col, Layout, Typography, message } from 'antd';
import { connect } from "react-redux";
import { withRouter, Redirect } from 'react-router-dom';
import * as actions from '../redux/actions/auth/auth';
import { saveFCMToken } from '../redux/actions/user/saveFCMToken';

class Login extends Component {
   state = {
      visible: false,
   }
   componentDidUpdate() {
      if (this.props.error) {
         message.error(this.props.error);
      }
   }
   layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
   };
   tailLayout = {
      wrapperCol: { lg: { offset: 6, span: 16 }, sm: { offset: 2 }, md: { offset: 6 } },
   };

   onFinish = async (values) => {
      const { username, password } = values;
      await this.props.onAuth(username, password);
      if (!this.props.error) {
         const fcmToken = localStorage.getItem('agentFCMToken')
         fcmToken && this.props.onSaveFcm(fcmToken);
      }
   }

   onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
   };
   render() {
      const styles = {
         container: {
            height: '100vh'
         },
         form: {
            marginTop: '100px'
         },
         head: {
            display: 'flex',
            backgroundColor: '#001529',
            justifyContent: 'center'
         },
         header: {
            fontSize: 24
         },
      }
      let redirect = null;
      if (this.props.isAuthenticated) {
         redirect = <Redirect to={this.props.location.state?.from || '/'} />
      }
      return (
         <Layout style={styles.container}>
            {redirect}
            <Layout.Header>
               <div style={styles.head}>
                  <Typography.Text type="success" style={styles.header}>555MIX Agent</Typography.Text>
               </div>
            </Layout.Header>
            <Layout.Content>
               <Row justify="center" align="middle">
                  <Col lg={8} sm={20}>
                     <Form
                        style={styles.form}
                        align="left"
                        {...this.layout}
                        justify="center"
                        name="basic"
                        initialValues={{
                           remember: true,
                        }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                     >
                        <Form.Item
                           label="Username"
                           name="username"
                           rules={[
                              {
                                 required: true,
                                 message: 'Please input your username!',
                              },
                           ]}
                        >
                           <Input />
                        </Form.Item>

                        <Form.Item
                           label="Password"
                           name="password"
                           rules={[
                              {
                                 required: true,
                                 message: 'Please input your password!',
                              },
                           ]}
                        >
                           <Input.Password />
                        </Form.Item>

                        <Form.Item {...this.tailLayout} name="remember" valuePropName="checked">
                           <Checkbox>Remember me</Checkbox>
                        </Form.Item>

                        <Form.Item {...this.tailLayout}>
                           <Button type="primary" htmlType="submit" loading={this.props.loading}>
                              Submit
                           </Button>
                        </Form.Item>
                     </Form>
                  </Col>
               </Row>
            </Layout.Content>
         </Layout>
      )
   }
}
const mapStateToProps = state => {
   return {
      loading: state.auth.loading,
      error: state.auth.error,
      isAuthenticated: state.auth.token !== null,
   };
};

const mapDispatchToProps = dispatch => {
   return {
      onAuth: async (email, password) =>
         await dispatch(actions.auth(email, password)),
      onSaveFcm: (token) => dispatch(saveFCMToken(token)),
   };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));