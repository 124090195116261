export const CREATE_TRANSACTION_START='CREATE_TRANSACTION_START';
export const CREATE_TRANSACTION_SUCCESS='CREATE_TRANSACTION_SUCCESS';
export const CREATE_TRANSACTION_FAIL='CREATE_TRANSACTION_FAIL';

export const TOGGLE_TRANSACTION_ADD_MODAL='TOGGLE_TRANSACTION_ADD_MODAL';

export const GET_AGENT_TRANSACTION_START='GET_AGENT_TRANSACTION_START';
export const GET_AGENT_TRANSACTION_SUCCESS='GET_AGENT_TRANSACTION_SUCCESS';
export const GET_AGENT_TRANSACTION_FAIL='GET_AGENT_TRANSACTION_FAIL';

export const GET_AGENT_TRANSACTION_DETAIL_START='GET_AGENT_TRANSACTION_DETAIL_START';
export const GET_AGENT_TRANSACTION_DETAIL_SUCCESS='GET_AGENT_TRANSACTION_DETAIL_SUCCESS';
export const GET_AGENT_TRANSACTION_DETAIL_FAIL='GET_AGENT_TRANSACTION_DETAIL_FAIL';

export const GET_MEMBER_TRANSACTION_START='GET_MEMBER_TRANSACTION_START';
export const GET_MEMBER_TRANSACTION_SUCCESS='GET_MEMBER_TRANSACTION_SUCCESS';
export const GET_MEMBER_TRANSACTION_FAIL='GET_MEMBER_TRANSACTION_FAIL';

export const GET_MEMBER_TRANSACTION_DETAIL_START='GET_MEMBER_TRANSACTION_DETAIL_START';
export const GET_MEMBER_TRANSACTION_DETAIL_SUCCESS='GET_MEMBER_TRANSACTION_DETAIL_SUCCESS';
export const GET_MEMBER_TRANSACTION_DETAIL_FAIL='GET_MEMBER_TRANSACTION_DETAIL_FAIL';