export const GET_DEPOSIT_START='GET_DEPOSIT_START';
export const GET_DEPOSIT_SUCCESS='GET_DEPOSIT_SUCCESS';
export const GET_DEPOSIT_FAIL='GET_DEPOSIT_FAIL';

export const COMPLETE_DEPOSIT_START='COMPLETE_DEPOSIT_START';
export const COMPLETE_DEPOSIT_SUCCESS='COMPLETE_DEPOSIT_SUCCESS';
export const COMPLETE_DEPOSIT_FAIL='COMPLETE_DEPOSIT_FAIL';

export const GET_WITHDRAW_START='GET_WITHDRAW_START';
export const GET_WITHDRAW_SUCCESS='GET_WITHDRAW_SUCCESS';
export const GET_WITHDRAW_FAIL='GET_WITHDRAW_FAIL';

export const COMPLETE_WITHDRAW_START='COMPLETE_WITHDRAW_START';
export const COMPLETE_WITHDRAW_SUCCESS='COMPLETE_WITHDRAW_SUCCESS';
export const COMPLETE_WITHDRAW_FAIL='COMPLETE_WITHDRAW_FAIL';

export const ADD_DEPOSIT='ADD_DEPOSIT';
export const ADD_WITHDRAW='ADD_WITHDRAW';