import update from '../utilities/update';
import { loading } from './shared/loading';
import { error } from './shared/error';
import {
   CREATE_PAYMENT_FAIL,
   CREATE_PAYMENT_START,
   CREATE_PAYMENT_SUCCESS,
   DELETE_PAYMENT_FAIL,
   DELETE_PAYMENT_START,
   DELETE_PAYMENT_SUCCESS,
   GET_PAYMENTS_FAIL,
   GET_PAYMENTS_START,
   GET_PAYMENTS_SUCCESS,
   GET_PAYMENT_TYPE_FAIL,
   GET_PAYMENT_TYPE_START,
   GET_PAYMENT_TYPE_SUCCESS,
   TOGGLE_ADD_PAYMENT_MODAL,
   TOGGLE_UPDATE_PAYMENT_MODAL,
   UPDATE_PAYMENT_FAIL,
   UPDATE_PAYMENT_START,
   UPDATE_PAYMENT_SUCCESS
} from '../actions/payment/actionTypes';

const initialState = {
   loading: false,
   error: null,
   message: null,
   all: [],
   addVisible: false,
   updateVisible: false,
   paymentTypes: [],
   paymentToUpdate: null
}

const addPayment = (state, action) => {
   return update(state, {
      loading: false,
      all: [...state.all, action.payload],
      message: 'Payment added successfully'
   })
}

const getPayments = (state, action) => {
   return update(state, {
      loading: false,
      all: action.payload?.agentPayments
   })
}

const toggleAddPaymentModal = (state, action) => {
   return update(state, {
      addVisible: !state.addVisible
   })
}

const getPaymentTypes = (state, action) => {
   return update(state, {
      loading: false,
      paymentTypes: action.payload
   })
}

const deletePayment = (state, action) => {
   return update(state, {
      loading: false,
      all: state.all.filter(value => value.id !== action.payload.id),
      message: `deleted payment ${action.payload.id}`
   })
}

const updatePayment = (state, action) => {
   return update(state, {
      loading: false,
      all: state.all.map(value => value.id === action.payload.id ? action.payload : value),
      message: `updated payment ${action.payload.id}`
   })
}

const toggleUpdate = (state, action) => {
   return update(state, {
      updateVisible: !state.updateVisible,
      paymentToUpdate: action.payload
   })
}

const paymentReducer = (state = initialState, action) => {
   switch (action.type) {
      case CREATE_PAYMENT_START:
         return loading(state, action);
      case CREATE_PAYMENT_SUCCESS:
         return addPayment(state, action);
      case CREATE_PAYMENT_FAIL:
         return error(state, action);
      case GET_PAYMENTS_START:
         return loading(state, action);
      case GET_PAYMENTS_SUCCESS:
         return getPayments(state, action);
      case GET_PAYMENTS_FAIL:
         return error(state, action);
      case TOGGLE_ADD_PAYMENT_MODAL:
         return toggleAddPaymentModal(state, action);
      case GET_PAYMENT_TYPE_START:
         return loading(state, action);
      case GET_PAYMENT_TYPE_SUCCESS:
         return getPaymentTypes(state, action);
      case GET_PAYMENT_TYPE_FAIL:
         return error(state, action);
      case DELETE_PAYMENT_START:
         return loading(state, action);
      case DELETE_PAYMENT_SUCCESS:
         return deletePayment(state, action);
      case DELETE_PAYMENT_FAIL:
         return error(state, action);
      case UPDATE_PAYMENT_START:
         return loading(state, action);
      case UPDATE_PAYMENT_SUCCESS:
         return updatePayment(state, action);
      case UPDATE_PAYMENT_FAIL:
         return error(state, action);
      case TOGGLE_UPDATE_PAYMENT_MODAL:
         return toggleUpdate(state, action);
      default:
         return state;
   }
}

export default paymentReducer;