import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, message, InputNumber, Radio, Checkbox } from 'antd';
import { createTransaction } from '../../redux/actions/transaction/createTransaction';
import { toggleTransactionAddModal } from '../../redux/actions/transaction/toggleTransactionAddModal';
import { getOneMember } from '../../redux/actions/member/getOneMember';
import { withRouter } from 'react-router-dom';
import { getCurrentUser } from '../../redux/actions/user/getCurrentUser';

const layout = {
   labelCol: { span: 8 },
   wrapperCol: { span: 12 },
};

class CreateTransactionModal extends React.Component {
   constructor(props) {
      super(props);
      this.form = React.createRef();
   }
   state = {
      creditVisible: true,
      credit: false,
   }
   onFinish = async (values) => {
      await this.props.onCreate({
         memberId: this.props.member?.id,
         command: values.command,
         amount: values.amount,
         credit: this.state.credit
      });
      if (this.props.error) {
         message.error(this.props.error);
      }
      if (this.props.message) {
         message.success(this.props.message);
      }
      this.form.current.resetFields();
      this.setState(prevState => {
         return {
            ...prevState,
            creditVisible: true,
         }
      });
      this.props.onClose();
   }
   onFinishFailed = (errors) => {
      console.log(errors)
   }
   handleCancel = () => {
      this.props.onClose();
   }
   onChange = ({ target: { value } }) => {
      if (value === "add") {
         this.setState(prevState => {
            return {
               ...prevState,
               creditVisible: true,
            }
         })
      }
      if (value === "remove") {
         this.setState(prevState => {
            return {
               ...prevState,
               creditVisible: false,
               credit: false,
            }
         })
      }
   }
   handleCheck = ({ target: { checked } }) => {
      this.setState(prevState => {
         return {
            ...prevState,
            credit: checked
         }
      })
   }
   render() {
      return (
         <Modal
            title="Manage Balance"
            ket="t-modal"
            centered
            visible={this.props.visible}
            okButtonProps={{ form: 'create-t-form', key: 'submit', htmlType: 'submit' }}
            confirmLoading={this.props.loading}
            onCancel={this.handleCancel}
            okText="Save"
            loading={this.props.loading}
         >
            <Form
               {...layout}
               ref={this.form}
               style={{ margin: 25 }}
               id="create-t-form"
               name="basic"
               onFinish={this.onFinish}
               onFinishFailed={this.onFinishFailed}
               initialValues={{
                  command: "add"
               }}
            >
               <Form.Item
                  name="command"
                  label="Command"
                  rules={[
                     {
                        required: true,
                        message: 'Require!',
                     }
                  ]}
               >
                  <Radio.Group onChange={this.onChange}>
                     <Radio value="add">add</Radio>
                     <Radio value="remove">remove</Radio>
                  </Radio.Group>
               </Form.Item>
               <Form.Item
                  name="amount"
                  label="Amount"
                  rules={[
                     {
                        required: true,
                        message: 'Require!',
                     }
                  ]}
                  hasFeedback
               >
                  <InputNumber min={0} style={{ width: '180px' }} />
               </Form.Item>
               {this.state.creditVisible && <Form.Item
                  name="credit"
                  label="Credit"
               >
                  <Checkbox onChange={this.handleCheck} checked={this.state.credit} />
               </Form.Item> || null}
            </Form>
         </Modal>
      );
   }
}

const mapStateToProps = state => ({
   visible: state.transaction.addVisible,
   loading: state.transaction.loading,
   error: state.transaction.error,
   message: state.transaction.message,
   member: state.member.one
})

const mapDispatchToProps = dispatch => ({
   onGetMember: (id) => dispatch(getOneMember(id)),
   onCreate: (obj) => dispatch(createTransaction(obj)),
   onClose: () => dispatch(toggleTransactionAddModal()),
   onGetCurrentUser: () => dispatch(getCurrentUser())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateTransactionModal));