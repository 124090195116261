import React from 'react';
import { Row, Col, Table, message, PageHeader, Input } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getMembers } from '../../redux/actions/member/getMembers';
import {
   EyeOutlined
} from '@ant-design/icons';

class MemberList extends React.Component {
   state = {
      searchKey: "",
      searchResult: []
   }
   async componentDidMount() {
      await this.props.onGetMembers();
   }
   componentDidUpdate() {
      if (this.props.error) {
         message.error(this.props.error);
      }
   }
   onSearch = ({ target: { value } }) => {
      const result = this.props.members?.filter(el => `${el.username}`.toLowerCase().includes(value.toLowerCase())
      || `${el.name}`.toLowerCase().includes(value.toLowerCase()));
      this.setState(oldState => ({
         ...oldState,
         searchResult: result,
         searchKey: value
      }))
   }

   render() {
      const columns = [
         {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a?.name?.toLowerCase() > b?.name?.toLowerCase(),
            sortDirections: ['descend'],
         },
         {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            align:'center'
         },
         {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
            align:'right'
         },
         {
            title: 'Detail',
            align:'center',
            render: (text, record) => (<EyeOutlined onClick={
               () => this.props.history.push(`/members/${record.id}`)
            } />)
         }
      ]
      return (<>
         <Row gutter={[16, 16]}>
            <Col span={{ lg: 6, md: 10, sm: 20 }}>
               <Input.Search
                  style={{ width: '220px' }}
                  placeholder="Search"
                  onChange={this.onSearch}
                  enterButton />
            </Col>
            <Col span={24} style={{
               width: '100%',
               overflowX: 'scroll',
            }}>
               <Table columns={columns} dataSource={this.state.searchKey ?
                  this.state.searchResult : this.props.members}
                  loading={this.props.loading} />
            </Col>
         </Row>
      </>)
   }
}

const mapStateToProps = state => ({
   loading: state.member.loading,
   members: state.member.all,
   error: state.member.error
})

const mapDispatchToProps = dispatch => ({
   onGetMembers: () => dispatch(getMembers())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberList));