import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

const makeRequest = async (options, dispatch) => {
    console.log(options)
    dispatch({
        type: actionTypes.UPDATE_MEMBER_START
    })
    await axios(options)
        .then(response =>dispatch({
            type: actionTypes.UPDATE_MEMBER_SUCCESS,
            payload: response.data
        }))
        .catch(error => dispatch({
            type: actionTypes.UPDATE_MEMBER_FAIL,
            payload: getMessage(error)
        }))
}

export const updateBasicInfo = ({
    id,
    name,
    mobile
}) => async dispatch => {
    const options = {
        url: `/members/${id}/basicInfo`,
        method: 'PUT',
        data: {
            name,
            mobile
        }
    }
    await makeRequest(options, dispatch);
}

export const updateBetLimit = ({
    id,
    limitId,
    maxForSingleBet,
    maxForMixBet,
}) => async dispatch => {
    const options = {
        url: `/members/${id}/betLimitation/${limitId}`,
        method: 'PUT',
        data: {
            maxForSingleBet,
            maxForMixBet,
        }
    }
    await makeRequest(options, dispatch);
}

export const updateCommission = ({
    id,
    commissionId,
    matchCount,
    betCommission,
    winCommission,
}) => async dispatch => {
    const options = {
        url: `/members/${id}/commissions/${commissionId}`,
        method: 'PUT',
        data: {
            matchCount,
            betCommission,
            winCommission,
        }
    }
    await makeRequest(options, dispatch);
}


export const updateSingleBetCommission = ({
    id,
    singleBetCommissionId,
    betCommission,
    winCommission,
    highBetCommission,
    highWinCommission
}) => async dispatch => {
    const options = {
        url: `/members/${id}/singleBetCommission/${singleBetCommissionId}`,
        method: 'PUT',
        data: {
            betCommission,
            winCommission,
            highBetCommission,
            highWinCommission
        }
    }
    await makeRequest(options, dispatch);
}

export const updateTwoThreeBet = (
      id,
      twoThreeBet
  ) => async dispatch => {
      const options = {
          url: `/members/${id}/twoThreeBet`,
          method: 'PUT',
          data: {
              twoThreeBet
          }
      }
      await makeRequest(options, dispatch);
  }
  
  export const updateTwoDCommission = ({
      id,
      commission
  }) => async dispatch => {
      const options = {
          url: `/members/${id}/twoDCommission`,
          method: 'PUT',
          data: {
              commission
          }
      }
      await makeRequest(options, dispatch);
  }
  
  export const updateThreeDCommission = ({
      id,
      commission
  }) => async dispatch => {
      const options = {
          url: `/members/${id}/threeDCommission`,
          method: 'PUT',
          data: {
              commission
          }
      }
      await makeRequest(options, dispatch);
  }

  export const update4DCommission = ({
      id,
      commission
  }) => async dispatch => {
      const options = {
          url: `/members/${id}/fourDCommission`,
          method: 'PUT',
          data: {
              commission
          }
      }
      await makeRequest(options, dispatch);
  }