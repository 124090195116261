import React from 'react';
import { Row, Col, Table, PageHeader, Image, Button, message } from 'antd';
import { getDeposits } from '../../redux/actions/deposit-withdraw/getDeposits';
import { completeDeposit } from '../../redux/actions/deposit-withdraw/completeDeposit';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
   CheckOutlined
} from '@ant-design/icons';


class Deposits extends React.Component {
   async componentDidMount() {
      await this.props.onGetDeposits();
   }

   componentDidUpdate() {
      if (this.props.message) {
         message.success(this.props.message);
      }
      if (this.props.error) {
         message.error(this.props.error);
      }
   }

   handleComplete = (id) => {
      this.props.onComplete(id);
   }

   columns = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id'
      },
      {
         title: 'Amount',
         dataIndex: 'amount',
         key: 'amount'
      },
      {
         title: 'AccountNo',
         key: 'agentPayment',
         render: (text, record) => record?.agentPayment?.accountNo
      },
      {
         title: 'Type',
         key: 'payment',
         render: (text, record) => record?.agentPayment?.payment?.name
      },
      {
         title: 'Username',
         key: 'username',
         render: (text, record) => <Link to={`/members/${record?.user?.id}`}>
            {record?.user?.username}</Link>
      },
      {
         title: 'Screenshot',
         key: 'ss',
         render: (text, record) => <Image
            width="50px"
            height="50px"
            key={`img${record?.id}`}
            src={record?.imageUrl} />
      },
      {
         title: 'Action',
         key: 'action',
         render: (text, record) => <Button
            loading={this.props.loading}
            onClick={() => this.handleComplete(record?.id)}>
            <CheckOutlined />
         </Button>
      }
   ]

   render() {
      return (
         <>
            <PageHeader title={<h5>Deposits</h5>} />
            <Row>
               <Col span={24} style={{
                  width: '100%',
                  overflowX: 'scroll',
                  scrollbarWidth: 'none'
               }}>
                  <Table
                     pagination={false}
                     key="deposit-table"
                     dataSource={this.props.deposits}
                     columns={this.columns}
                     loading={this.props.loading} />
               </Col>
            </Row>
         </>
      );
   }
}

const mapStateToProps = state => ({
   deposits: state.depositWithdraw.deposits,
   error: state.depositWithdraw.error,
   loading: state.depositWithdraw.loading,
   message: state.depositWithdraw.message,
});

const mapDispatchToProps = dispatch => ({
   onGetDeposits: () => dispatch(getDeposits()),
   onComplete: (id) => dispatch(completeDeposit(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Deposits);