import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';
import store from './redux/store';
import { Provider } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import ConfigRoute from './app/ConfigRoute';

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function (registration) {
      console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function (err) {
      console.log("Service worker registration failed, error:", err);
    });
}

ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <ConfigRoute />
      </BrowserRouter>
    </Provider>,
  document.getElementById('root')
);

reportWebVitals();