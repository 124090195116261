import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, message, InputNumber } from 'antd';
import * as actionTypes from '../../redux/actions/member/actionTypes';
import { updateThreeDCommission } from '../../redux/actions/member/updateMember';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

class Update3DCommissionModal extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }
    onFinish = async (values) => {
        await this.props.onUpdate({
            id:this.props.member?.id,
            commission:values.threeDCommission
        });
        if (this.props.error) {
            message.error(this.props.error);
        }
        if (this.props.message) {
            message.success(this.props.message);
        }
        this.form.current.resetFields();
        this.props.onClose();
    }
    onFinishFailed = (errors) => {
        console.log(errors)
    }
    handleCancel = () => {
        this.props.onClose();
    }
    render() {
        return (
            <Modal
                title="Update ThreeD Commission"
                key={this.props.member?.id}
                centered
                visible={this.props.visible}
                okButtonProps={{ form: 'update-3dc-form', key: 'submit', htmlType: 'submit' }}
                confirmLoading={this.props.loading}
                onCancel={this.handleCancel}
                okText="Save"
                loading={this.props.loading}
            >
                <Form
                {...layout}
                    ref={this.form}
                    style={{ margin: 25 }}
                    id="update-3dc-form"
                    name="basic"
                    initialValues={{
                        threeDCommission:this.props.member?.threeDCommission,
                    }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        name="threeDCommission"
                        label="Commission"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber  style={{width:150}}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    visible: state.member.update3dCommissionVisible,
    loading: state.member.loading,
    token: state.auth.token,
    error: state.member.error,
    message: state.member.message,
    member:state.member.one
})

const mapDispatchToProps = dispatch => ({
    onUpdate: (obj) => dispatch(updateThreeDCommission(obj)),
    onClose: () => dispatch({
        type:actionTypes.TOGGLE_3D_COMMISSION_UPDATE
    }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Update3DCommissionModal);