import { Button, message } from 'antd';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTwoDReport } from '../../redux/actions/report/getTwoDReport';
import generatePDF from './Service/generatePDF';

export default function TwoDReport() {
      const dispatch = useDispatch();
      const { twoDs, error, twoDAverage, twoDtotal, twoDmax, twoDmaxNumber, loading } = useSelector(state => state.report);
      useEffect(async () => {
            await dispatch(getTwoDReport());
            if (error) {
                  message.error(error);
            }
      }, []);
      const isMobile = window.innerWidth <= 1024;
      return (
            <>
                  <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        marginBottom: '20px'
                  }}>
                        <span>Total Amount:{parseInt(twoDtotal)}</span>
                        <span>Max:{parseInt(twoDmax)}$ | <span style={{ color: 'red' }}>{parseInt(twoDmaxNumber??0)}</span></span>
                        <span>Average:{parseInt(twoDAverage)}</span>
                        <Button
                              onClick={() => generatePDF('2D', twoDs)}
                              loading={loading}
                        >
                              Export as PDF
                        </Button>
                  </div>
                  <div style={{ width: '100%', overflow: 'auto' }}>
                        <div style={{ boxSizing: 'content-box', overflowX: 'scroll', scrollbarWidth: 0 }}>
                              <div style={{
                                    display: 'grid',
                                    gridTemplateColumns: isMobile ? "repeat(10,60px)" : "repeat(10,100px)",
                                    gridTemplateRows: isMobile ? "repeat(10,60px)" : "repeat(10,100px)",
                                    gridGap: '5px',
                                    justifyContent: 'start',
                                    boxSizing: 'content-box',
                              }}>
                                    {
                                          twoDs?.map(obj => <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                padding: 5,
                                                border: '1px solid gray',
                                                borderBottom: (obj.status == 1) ? '5px solid green' : obj.status == 2 ? '5px solid orange' : obj.status == 3 ? '5px solid red' : '1px solid gray',
                                                justifyContent: 'center',
                                                color: '#0e0e0e'
                                          }}>
                                                <div style={{
                                                      display: 'flex',
                                                      flexDirection: 'row',
                                                      justifyContent: 'space-between'
                                                }}>
                                                      <span>
                                                            {obj.twoDNumber}
                                                      </span>
                                                </div>
                                                <span style={{
                                                      fontSize: '0.6rem',
                                                      color: 'gray'
                                                }}>
                                                      Amount
                                                </span>
                                                <span>
                                                      {`${obj.totalBetAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                </span>
                                          </div>)
                                    }
                              </div>
                        </div>
                  </div>
            </>
      );
}