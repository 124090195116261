import { Tabs } from 'antd';
import MemberDetail from './MemberDetail';
import MemberTransactions from './MemberTransactions';

const Dashboard = () => {
    const { TabPane } = Tabs;
    return (
        <Tabs defaultActiveKey="1">
            <TabPane tab="Member Detail" key="1">
                <MemberDetail />
            </TabPane>
            <TabPane tab="Transaction" key="2">
                <MemberTransactions />
            </TabPane>
        </Tabs>
    );
}

export default Dashboard;