export const CREATE_PAYMENT_START='CREATE_PAYMENT_START';
export const CREATE_PAYMENT_SUCCESS='CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_FAIL='CREATE_PAYMENT_FAIL';

export const GET_PAYMENTS_START='GET_PAYMENTS_START';
export const GET_PAYMENTS_SUCCESS='GET_PAYMENTS_SUCCESS';
export const GET_PAYMENTS_FAIL='GET_PAYMENTS_FAIL';

export const TOGGLE_ADD_PAYMENT_MODAL='TOGGLE_ADD_PAYMENT_MODAL';

export const GET_PAYMENT_TYPE_START='GET_PAYMENT_TYPE_START';
export const GET_PAYMENT_TYPE_SUCCESS='GET_PAYMENT_TYPE_SUCCESS';
export const GET_PAYMENT_TYPE_FAIL='GET_PAYMENT_TYPE_FAIL';

export const UPDATE_PAYMENT_START='UPDATE_PAYMENT_START';
export const UPDATE_PAYMENT_SUCCESS='UPDATE_PAYMENT_SUCCESS';
export const UPDATE_PAYMENT_FAIL='UPDATE_PAYMENT_FAIL';

export const DELETE_PAYMENT_START='DELETE_PAYMENT_START';
export const DELETE_PAYMENT_SUCCESS='DELETE_PAYMENT_SUCCESS';
export const DELETE_PAYMENT_FAIL='DELETE_PAYMENT_FAIL';

export const TOGGLE_UPDATE_PAYMENT_MODAL='TOGGLE_UPDATE_PAYMENT_MODAL';
