import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const createPayment=({
    paymentId,
    name,
    accountNo
})=>async dispatch=>{
    dispatch({
        type:actionTypes.CREATE_PAYMENT_START
    });
    const options={
        url:`/agentPayments`,
        method:'POST',
        data:{
            paymentId,
            name,
            accountNo
        }
    }
    await axios(options)
            .then(response=>{
                dispatch({
                type:actionTypes.CREATE_PAYMENT_SUCCESS,
                payload:response.data
            });
        })
            .catch(error=>dispatch({
                type:actionTypes.CREATE_PAYMENT_FAIL,
                payload:getMessage(error)
            }))
}