import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const getMemberTransactionDetail=(id,date)=>async dispatch=>{
    dispatch({
        type:actionTypes.GET_MEMBER_TRANSACTION_DETAIL_START
    })
    await axios.get(`transactions/member/${id}/${date}`)
                .then(response=>dispatch({
                    type:actionTypes.GET_MEMBER_TRANSACTION_DETAIL_SUCCESS,
                    payload:response.data
                }))
                .catch(error=>dispatch({
                    type:actionTypes.GET_MEMBER_TRANSACTION_DETAIL_FAIL,
                    payload:getMessage(error)
                }))
}