import {useState} from 'react';

const BetInformations = ({
    id,
    agentBetAmount,
    userBetAmount,
    agentCommissionAmount = 0,
    agentWinAmount,
    userWinAmount,
    userCommissionAmount=0,
    status,
    date
}) => {
   const query=window.matchMedia(`(max-width: 480px)`);
   const [isMobile,setIsMobile]=useState(query.matches);
   query.onchange=(e)=>{
      setIsMobile(e.matches);
   }
   const styles = {
      container: {
         width: '100%',
         padding: '30px',
         display: 'flex',
         color: '#666',
         lineHeight: '2rem',
         flexDirection: isMobile ? "column" : "row"
      },
      child: {
         flex: 1
      }
   }
    return <>
        <div style={styles.container}>
            <div style={styles.child}>
                <div>BET ID : {id}</div>
                <div>Time : {date}</div>
            </div>
            <div style={styles.child}>
                <div>Master Bet : {agentBetAmount}</div>
                <div>User Bet : {userBetAmount}</div>
            </div>
            <div style={styles.child}>
                <div>Master % : {agentCommissionAmount}</div>
                <div>User % : {userCommissionAmount}</div>
            </div>
            <div style={styles.child}>
                <div>Master Win : {agentWinAmount}</div>
                <div>User Win : {userWinAmount}</div>
            </div>
            <div style={styles.child}>Status : {status}</div>
        </div>
    </>
}

export default BetInformations;