import React from 'react';
import {Button, Col, DatePicker, Form, message, Row, Select, Table} from 'antd';
import {connect} from 'react-redux';
import {getMainMemberReport} from '../../redux/actions/report/getMainMemberReport';
import {setBetType} from '../../redux/actions/report/setBetType';
import currency from 'currency.js';
import './index.css';
import {ParseIntAndFormat} from "./ParseIntAndFormat";

class MainMemberReport extends React.Component {
    state = {
        startDate: null,
        endDate: null,
        isMobile: false,
    }

    componentDidMount() {
        const query = window.matchMedia(`(max-width: 480px)`);
        query.onchange = (e) => {
            this.setState(prevState => {
                return {
                    ...prevState,
                    isMobile: e.matches
                }
            })
        }
        this.setState({
            isMobile: query.matches
        })
    }

    dateHandler = (value, dateString) => {
        this.setState(prevState => {
            return {
                ...prevState,
                startDate: dateString[0],
                endDate: dateString[1]
            }
        });
    }
    onFinish = async (values) => {
        const {startDate, endDate} = this.state;
        await this.props.onGetReport({startDate, endDate, type: this.props.type});
        if (this.props.error) {
            message.error(this.props.error);
        }
    }
    onFinishFailed = (errors) => {
        console.warn(errors);
    }

    render() {
        const {isMobile} = this.state;
        const {RangePicker} = DatePicker;
        const {Column, ColumnGroup} = Table;
        const {Option} = Select;
        return (
            <Row gutter={[16, 10]}>
                <Col span={{lg: 12, md: 12, sm: 24}}>
                    <Form
                        name="dates"
                        layout={isMobile ? "horizontal" : "inline"}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                    >
                        {this.props.agent?.twoThreeAllowed ? (
                            <Form.Item>
                                <Select
                                    defaultValue={this.props.type}
                                    onChange={(value) => this.props.onSetBetType(value)}>
                                    <Option value="SOCCER">Soccer</Option>
                                    {/* <Option value="TWOD">Two D</Option> */}
                                    <Option value="THREED">Three D</Option>
                                    {/* <Option value="FOURD">Four D</Option> */}
                                </Select>
                            </Form.Item>
                        ) : null}
                        <Form.Item
                            name="dates"
                            validateStatus="warning"
                        >
                            <RangePicker onChange={this.dateHandler} format="YYYY-MM-DD"/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Load
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={24} style={{
                    width: '100%',
                    overflowX: 'scroll',
                    scrollbarWidth: 'none'
                }}>
                    <Table
                        bordered
                        loading={this.props.loading}
                        dataSource={this.props.members}
                        pagination={false}
                        summary={
                            (data) => {
                                let agentBetAmount = currency(0);
                                let agentCommissionAmount = currency(0);
                                let agentWinLoseAmount = currency(0);
                                let userBetAmount = currency(0);
                                let userCommissionAmount = currency(0);
                                let userWinLoseAmount = currency(0);
                                data?.map(obj => {
                                    agentBetAmount = agentBetAmount.add(currency(obj.agentBetAmount));
                                    agentCommissionAmount = agentCommissionAmount.add(currency(obj.agentCommissionAmount));
                                    agentWinLoseAmount = agentWinLoseAmount.add(currency(obj.agentBetAmount)
                                        .subtract(currency(obj.agentWinAmount))
                                        .subtract(currency(obj.agentCommissionAmount)));
                                    userBetAmount = userBetAmount.add(currency(obj.userBetAmount));
                                    userCommissionAmount = userCommissionAmount.add(currency(obj.userCommissionAmount));
                                    userWinLoseAmount = userWinLoseAmount.add(currency(obj.userBetAmount)
                                        .subtract(currency(obj.userWinAmount))
                                        .subtract(currency(obj.userCommissionAmount)));
                                });
                                return <Table.Summary.Row style={{textAlign: 'right'}}>
                                    <Table.Summary.Cell>Total</Table.Summary.Cell>
                                    <Table.Summary.Cell>{ParseIntAndFormat(agentBetAmount.value ?? 0)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{ParseIntAndFormat(agentCommissionAmount.value ?? 0)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{ParseIntAndFormat(agentWinLoseAmount.value ?? 0)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{ParseIntAndFormat(userBetAmount.value ?? 0)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{ParseIntAndFormat(userCommissionAmount.value ?? 0)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{ParseIntAndFormat(userWinLoseAmount.value ?? 0)}</Table.Summary.Cell>
                                </Table.Summary.Row>
                            }
                        }
                    >
                        <Column title="Member Name"
                                sorter={(a, b) => a.userName?.toLowerCase() > b.userName?.toLowerCase()}
                                sortDirections={['descend']}
                                dataIndex="userName" key="userName"
                                align="center"/>
                        <ColumnGroup title="Master">
                            <Column title="Bet" dataIndex="agentBetAmount"
                                    render={(text, record) => ParseIntAndFormat(record.agentBetAmount ?? 0)}
                                    key="agentBetAmount" align="right"/>
                            <Column title="Commission" dataIndex="agentCommissionAmount"
                                    render={(text, record) => ParseIntAndFormat(record.agentCommissionAmount ?? 0)}
                                    key="agentCommissionAmount" align="right"/>
                            <Column
                                title="W/L"
                                key="p_win_lose"
                                render={(text, record) => {
                                    let sum = currency(record.agentBetAmount)
                                        .subtract(currency(record.agentWinAmount))
                                        .subtract(currency(record.agentCommissionAmount))
                                        .value;
                                    return ParseIntAndFormat(sum);
                                }
                                }
                                align="right"
                            />
                        </ColumnGroup>
                        <ColumnGroup title="Agent">
                            <Column title="Bet" dataIndex="userBetAmount"
                                    render={(text, record) => ParseIntAndFormat(record.userBetAmount ?? 0)}
                                    key="userBetAmount" align="right"/>
                            <Column title="Commission" dataIndex="userCommissionAmount"
                                    render={(text, record) => ParseIntAndFormat(record.userCommissionAmount ?? 0)}
                                    key="userCommissionAmount" align="right"/>
                            <Column
                                title="W/L"
                                key="s_win_lose"
                                render={(text, record) => {
                                    let sum = currency(record.userBetAmount)
                                        .subtract(currency(record.userWinAmount))
                                        .subtract(currency(record.userCommissionAmount))
                                        .value;
                                    return ParseIntAndFormat(sum);
                                }
                                }
                                align="right"
                            />
                        </ColumnGroup>
                    </Table>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.report.loading,
    error: state.report.error,
    members: state.report.mainMembers,
    type: state.report.type,
    agent: state.user.current
})

const mapDispatchToProps = dispatch => ({
    onGetReport: (dates) => dispatch(getMainMemberReport(dates)),
    onSetBetType: (type) => dispatch(setBetType(type))
})

export default connect(mapStateToProps, mapDispatchToProps)(MainMemberReport);