import React from 'react';
import { Row, Col, Form, Button, Input, InputNumber, message, Switch } from 'antd';
import { connect } from 'react-redux';
import { createMember } from '../../redux/actions/member/createMember';

const COLUMN_WIDTH = {
      lg: 12,
      md: 12,
      sm: 24,
      xs: 24
}

const layout = {
      wrapperCol: { span: 24 },
};
const commmissionLayout = {
      labelCol: {
            span: 10
      },
      wrapperCol: {
            span: 24
      }
}


class CreateMember extends React.Component {
      constructor(props) {
            super(props);
            const lastCreatedMember = localStorage.getItem('member');
            this.member = JSON.parse(lastCreatedMember);
            this.form = React.createRef();
            this.state = {
                  commission: this.member?.commissions || [
                        {
                              matchCount: 0,
                              betCommission: 0,
                              winCommission: 0
                        }
                  ],
            }
            this.mixBetComInitials = {};
            this.props.agent?.commissions.map((value, index) => {
                  this.mixBetComInitials[`betCommission${index}`] = this.state.commission[index]?.betCommission || 0
            });
      }
      onFinish = async (values) => {
            const { singleBetCommission: {
                  winCommission,
                  highWinCommission
            } } = this.props.agent;
            const request = {
                  name: values.name,
                  username: values.username,
                  password: values.password,
                  mobile: values.mobile,
                  twoThreeAllowed: true,
                  twoDCommission: 0,
                  threeDCommission: values.threeDCommission,
                  // fourDCommission:values.fourDCommission,
                  betLimitation: {
                        maxForSingleBet: values.maxForSingleBet,
                        maxForMixBet: values.maxForMixBet,
                  },
                  singleBetCommission: {
                        betCommission: values.betCommission,
                        highBetCommission: values.highBetCommission,
                        winCommission,
                        highWinCommission
                  },
                  commissions: this.props.agent?.commissions.map((com, i) => {
                        return {
                              ...com,
                              betCommission: values[`betCommission${i}`]
                        }
                  })
            }
            await this.props.onCreate(request);
            if (this.props.message) {
                  message.success(this.props.message);
                  localStorage.setItem('member', JSON.stringify(request));
                  this.form.current.resetFields();
            }
            if (this.props.error) {
                  message.error(this.props.error)
            }
      }
      onFinishFailed = (errors) => {
            console.log(errors);
      }
      render() {
            return (
                  <>
                        <Row>
                              <Col xxl={{ span: 18 }}
                                    xl={{ span: 18 }}
                                    lg={{ span: 18 }}
                                    md={{ span: 24 }}
                                    sm={{ span: 24 }}
                                    xs={{ span: 24 }}>
                                    <h2>Member</h2>
                                    <Form
                                          layout="vertical"
                                          key="mb"
                                          ref={this.form}
                                          {...layout}
                                          loading={this.props.loading}
                                          name="basic"
                                          onFinish={this.onFinish}
                                          onFinishFailed={this.onFinishFailed}
                                          initialValues={{
                                                maxForSingleBet: this.member?.betLimitation?.maxForSingleBet || 0,
                                                maxForMixBet: this.member?.betLimitation?.maxForMixBet || 0,
                                                betCommission: this.member?.singleBetCommission?.betCommission || 0,
                                                highBetCommission: this.member?.singleBetCommission?.highBetCommission || 0,
                                                // twoDCommission: this.member?.twoDCommission || 0,
                                                threeDCommission: this.member?.threeDCommission || 0,
                                                // fourDCommission:this.member?.fourDCommission || 0,
                                                ...this.mixBetComInitials
                                          }}
                                    >
                                          <h3>Basic Info</h3>
                                          <Row gutter={20}>
                                                <Col lg={{ span: COLUMN_WIDTH.lg }}
                                                      md={{ span: COLUMN_WIDTH.md }}
                                                      sm={{ span: COLUMN_WIDTH.sm }}
                                                      xs={{ span: COLUMN_WIDTH.xs }}>
                                                      <Form.Item
                                                            name="username"
                                                            label="Username"
                                                            rules={[
                                                                  {
                                                                        required: true,
                                                                        message: '3 Characters Exactly',
                                                                        max: 3,
                                                                        min: 3
                                                                  },
                                                            ]}
                                                      >
                                                            <Input prefix={this.props.agent?.username} span={24} />
                                                      </Form.Item>
                                                </Col>
                                                <Col lg={{ span: COLUMN_WIDTH.lg }}
                                                      md={{ span: COLUMN_WIDTH.md }}
                                                      sm={{ span: COLUMN_WIDTH.sm }}
                                                      xs={{ span: COLUMN_WIDTH.xs }}>
                                                      <Form.Item
                                                            name="name"
                                                            label="Name"
                                                            rules={[
                                                                  {
                                                                        required: true,
                                                                        message: 'require',
                                                                  },
                                                            ]}
                                                      >
                                                            <Input span={24} />
                                                      </Form.Item>
                                                </Col>
                                          </Row>
                                          <Row gutter={20}>
                                                <Col lg={{ span: COLUMN_WIDTH.lg }}
                                                      md={{ span: COLUMN_WIDTH.md }}
                                                      sm={{ span: COLUMN_WIDTH.sm }}
                                                      xs={{ span: COLUMN_WIDTH.xs }}>
                                                      <Form.Item
                                                            name="password"
                                                            label="Password"
                                                            rules={[
                                                                  {
                                                                        required: true,
                                                                        message: 'require',
                                                                  },
                                                            ]}
                                                      >
                                                            <Input span={24} />
                                                      </Form.Item>
                                                </Col>
                                                <Col lg={{ span: COLUMN_WIDTH.lg }}
                                                      md={{ span: COLUMN_WIDTH.md }}
                                                      sm={{ span: COLUMN_WIDTH.sm }}
                                                      xs={{ span: COLUMN_WIDTH.xs }}>
                                                      <Form.Item
                                                            name="mobile"
                                                            label="Mobile"
                                                            rules={[
                                                                  {
                                                                        required: true,
                                                                        message: 'require',
                                                                  },
                                                            ]}
                                                      >
                                                            <Input span={24} />
                                                      </Form.Item>
                                                </Col>
                                          </Row>
                                          <h3>Bet Limitation</h3>
                                          <Row gutter={20}>
                                                <Col lg={{ span: COLUMN_WIDTH.lg }}
                                                      md={{ span: COLUMN_WIDTH.md }}
                                                      sm={{ span: COLUMN_WIDTH.sm }}
                                                      xs={{ span: COLUMN_WIDTH.xs }}>
                                                      <Form.Item
                                                            name="maxForMixBet"
                                                            label={<label style={{ whiteSpace: 'normal' }}>Max For Mix Bet</label>}
                                                            rules={[
                                                                  {
                                                                        required: true,
                                                                        message: 'require',
                                                                  },
                                                            ]}
                                                      >
                                                            <InputNumber style={{ width: '100%' }} min={0} />
                                                      </Form.Item>
                                                </Col>
                                                <Col lg={{ span: COLUMN_WIDTH.lg }}
                                                      md={{ span: COLUMN_WIDTH.md }}
                                                      sm={{ span: COLUMN_WIDTH.sm }}
                                                      xs={{ span: COLUMN_WIDTH.xs }}>
                                                      <Form.Item
                                                            name="maxForSingleBet"
                                                            label={<label style={{ whiteSpace: 'normal' }}>Max For Single Bet</label>}
                                                            rules={[
                                                                  {
                                                                        required: true,
                                                                        message: 'require',
                                                                  },
                                                            ]}
                                                      >
                                                            <InputNumber style={{ width: '100%' }} min={0} />
                                                      </Form.Item>
                                                </Col>
                                          </Row>

                                          <h3>Single Bet Commission</h3>
                                          <Row gutter={20}>
                                                <Col lg={{ span: COLUMN_WIDTH.lg }}
                                                      md={{ span: COLUMN_WIDTH.md }}
                                                      sm={{ span: COLUMN_WIDTH.sm }}
                                                      xs={{ span: COLUMN_WIDTH.xs }}>
                                                      <Form.Item
                                                            name="betCommission"
                                                            label="Commission"
                                                            rules={[
                                                                  {
                                                                        required: true,
                                                                        message: 'require',
                                                                  },
                                                            ]}
                                                      >
                                                            <InputNumber
                                                                  style={{ width: '100%' }}
                                                                  min={0}
                                                                  max={this.props.agent?.singleBetCommission.betCommission}
                                                            />
                                                      </Form.Item>
                                                </Col>
                                                <Col lg={{ span: COLUMN_WIDTH.lg }}
                                                      md={{ span: COLUMN_WIDTH.md }}
                                                      sm={{ span: COLUMN_WIDTH.sm }}
                                                      xs={{ span: COLUMN_WIDTH.xs }}>
                                                      <Form.Item
                                                            name="highBetCommission"
                                                            label={<label style={{ whiteSpace: 'normal' }}>High Commission</label>}
                                                            rules={[
                                                                  {
                                                                        required: true,
                                                                        message: 'require',
                                                                  },
                                                            ]}
                                                      >
                                                            <InputNumber
                                                                  style={{ width: '100%' }}
                                                                  min={0}
                                                                  max={this.props.agent?.singleBetCommission?.highBetCommission}
                                                            />

                                                      </Form.Item>
                                                </Col>
                                                <Col lg={{ span: COLUMN_WIDTH.lg }}
                                                      md={{ span: COLUMN_WIDTH.md }}
                                                      sm={{ span: COLUMN_WIDTH.sm }}
                                                      xs={{ span: COLUMN_WIDTH.xs }}
                                                      style={{
                                                            height: '40px',
                                                      }}>
                                                      <div style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: "flex-start"
                                                      }}>
                                                            Tax : {this.props.agent?.singleBetCommission.winCommission}
                                                      </div>
                                                </Col>
                                                <Col lg={{ span: COLUMN_WIDTH.lg }}
                                                      md={{ span: COLUMN_WIDTH.md }}
                                                      sm={{ span: COLUMN_WIDTH.sm }}
                                                      xs={{ span: COLUMN_WIDTH.xs }}
                                                      style={{
                                                            height: '40px',
                                                      }}>
                                                      <div style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: "flex-start"
                                                      }}>
                                                            High Tax :  {this.props.agent?.singleBetCommission?.highWinCommission}
                                                      </div>
                                                </Col>
                                          </Row>
                                          {this.props.agent?.twoThreeAllowed ? (
                                                <>
                                                      <h3>Three Four Commission</h3>
                                                      <Row gutter={20}>
                                                            <Col lg={COLUMN_WIDTH.lg}
                                                                  md={COLUMN_WIDTH.md}
                                                                  sm={COLUMN_WIDTH.sm}>
                                                                  <Form.Item
                                                                        name="threeDCommission"
                                                                        label={<label style={{ whiteSpace: 'normal' }}>
                                                                              ThreeD Commission</label>}
                                                                        rules={[
                                                                              {
                                                                                    required: true,
                                                                                    message: 'require',
                                                                              },
                                                                        ]}
                                                                  >
                                                                        <InputNumber style={styles.inputNumber} min={0} />
                                                                  </Form.Item>
                                                            </Col>
                                                            {/* <Col lg={{ span: COLUMN_WIDTH.lg }}
                                                                  md={{ span: COLUMN_WIDTH.md }}
                                                                  sm={{ span: COLUMN_WIDTH.sm }}
                                                                  xs={{ span: COLUMN_WIDTH.xs }}>
                                                                  <Form.Item
                                                                        name="fourDCommission"
                                                                        label="FourD Commission"
                                                                        rules={[
                                                                              {
                                                                                    required: true,
                                                                                    message: 'require',
                                                                              },
                                                                        ]}
                                                                  >
                                                                        <InputNumber style={styles.inputNumber} min={0} />
                                                                  </Form.Item>
                                                            </Col> */}
                                                      </Row>
                                                </>
                                          ) : null}
                                          <br />
                                          <h3>Mix Bet Commissions</h3>
                                          {
                                                this.props.agent?.commissions?.map((commission, index) => {
                                                      return (
                                                            <Row gutter={20} style={{ marginTop: 20 }} align="center">
                                                                  <Col span={6}
                                                                        style={{
                                                                              paddingTop: '5px',
                                                                              height: '40px',
                                                                        }}>
                                                                        <div style={{
                                                                              width: '100%',
                                                                              height: '100%',
                                                                              display: 'flex',
                                                                              flexDirection: "column",
                                                                              gap: 10,
                                                                        }}>
                                                                              <span style={{
                                                                                    whiteSpace: 'nowrap',
                                                                                    textOverflow: 'ellipsis'
                                                                              }}>Match Count</span>
                                                                              <span>{commission.matchCount}</span>
                                                                        </div>
                                                                  </Col>
                                                                  <Col span={6}
                                                                        style={{
                                                                              paddingTop: '5px',
                                                                              height: '40px',
                                                                        }}>
                                                                        <div style={{
                                                                              width: '100%',
                                                                              height: '100%',
                                                                              display: 'flex',
                                                                              flexDirection: "column",
                                                                              gap: 10
                                                                        }}>
                                                                              <span>Tax</span>
                                                                              <span>{commission.winCommission}</span>
                                                                        </div>
                                                                  </Col>
                                                                  <Col span={12}>
                                                                        <Form.Item
                                                                              {...commmissionLayout}
                                                                              name={`betCommission${index}`}
                                                                              label="Commission"
                                                                              rules={[
                                                                                    {
                                                                                          required: true,
                                                                                          message: 'require'
                                                                                    },
                                                                              ]}
                                                                        >
                                                                              <InputNumber
                                                                                    min={0}
                                                                                    max={commission.betCommission}
                                                                                    style={{ width: '100%' }}
                                                                              />
                                                                        </Form.Item>
                                                                  </Col>

                                                            </Row>
                                                      );
                                                })
                                          }
                                          <Form.Item>
                                                <Button type="primary" htmlType="submit" loading={this.props.loading}>
                                                      Create
                                                </Button>
                                          </Form.Item>
                                    </Form>
                              </Col>
                        </Row>
                  </>
            );
      }

}

const styles = {
      inputNumber: {
            width: 150,
      },
      percent: {
            width: 100
      },
      info: {
            width: '100%',
            background: 'yellow'
      }
}

const mapStateToProps = state => ({
      message: state.member.message,
      error: state.member.error,
      loading: state.member.loading,
      agent: state.user.current
})

const mapDispatchToProps = dispatch => ({
      onCreate: (request) => dispatch(createMember(request))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateMember);