import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const getPayments=()=>async dispatch=>{
    dispatch({
        type:actionTypes.GET_PAYMENTS_START
    });
    await axios.get('/agentPayments/agent')
        .then(response=>{
            dispatch({
            type:actionTypes.GET_PAYMENTS_SUCCESS,
            payload:response.data
        });
    })
        .catch(error=>dispatch({
            type:actionTypes.GET_PAYMENTS_FAIL,
            payload:getMessage(error)
        }))
}