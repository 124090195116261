import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import ReduxThunk from 'redux-thunk';
import authReducer from './reducers/authReducer';
import memberReducer from './reducers/memberReducer';
import transactionReducer from './reducers/transactionReducer';
import reportReducer from "./reducers/reportReducer";
import betPatternReducer from "./reducers/betPatternReducer";
import userReducer from "./reducers/userReducer";
import agreementReducer from './reducers/agreementReducer';
import paymentReducer from './reducers/paymentReducer';
import depositWithdrawReducer from './reducers/depositWithdrawReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
    auth: authReducer,
    member:memberReducer,
    transaction:transactionReducer,
    report:reportReducer,
    betPattern:betPatternReducer,
    user:userReducer,
    agreement:agreementReducer,
    payment:paymentReducer,
    depositWithdraw:depositWithdrawReducer
  });
  
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(ReduxThunk))
  );
  
  export default store;