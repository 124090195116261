import update from '../utilities/update';
import { loading } from './shared/loading';
import { error } from './shared/error';
import {
    GET_DEPOSIT_START,
    GET_DEPOSIT_SUCCESS,
    GET_DEPOSIT_FAIL,
    COMPLETE_DEPOSIT_START,
    COMPLETE_DEPOSIT_SUCCESS,
    COMPLETE_DEPOSIT_FAIL,
    GET_WITHDRAW_START,
    GET_WITHDRAW_SUCCESS,
    GET_WITHDRAW_FAIL,
    COMPLETE_WITHDRAW_START,
    COMPLETE_WITHDRAW_SUCCESS,
    COMPLETE_WITHDRAW_FAIL,
    ADD_DEPOSIT,
    ADD_WITHDRAW
} from '../actions/deposit-withdraw/actionTypes';

const initialState = {
    loading: false,
    error: null,
    message: null,
    deposits: [],
    withdraws:[]
}

const depositWithdrawSuccess = (state, action) => {
    return update(state, {
        loading: false,
        deposits: action.payload
    })
}

const completeDeposit=(state,action)=>{
    return update(state,{
        loading:false,
        message:'Completed deposit',
        deposits:state.deposits.filter(value=>value.id!==action.payload.id)
    })
}

const completeWithdraw=(state,action)=>{
    return update(state,{
        loading:false,
        message:'Completed withdraw',
        withdraws:state.withdraws.filter(value=>value.id!==action.payload.id)
    })
}

const getWithdraws=(state,action)=>{
    return update(state,{
        loading:false,
        withdraws:action.payload
    })
}

const addDeposit=(state,action)=>{
    return update(state,{
        deposits:[...state.deposits,action.payload]
    })
}

const addWithdraw=(state,action)=>{
    return update(state,{
        withdraws:[...state.withdraws,action.payload]
    })
}

const depositWithdrawReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DEPOSIT_START:
            return loading(state, action);
        case GET_DEPOSIT_SUCCESS:
            return depositWithdrawSuccess(state, action);
        case GET_DEPOSIT_FAIL:
            return error(state, action);
        case COMPLETE_DEPOSIT_START:
            return loading(state,action);
        case COMPLETE_DEPOSIT_SUCCESS:
            return completeDeposit(state,action);
        case COMPLETE_DEPOSIT_FAIL:
            return error(state,action);
        case GET_WITHDRAW_START:
            return loading(state,action);
        case GET_WITHDRAW_SUCCESS:
            return getWithdraws(state,action);
        case GET_WITHDRAW_FAIL:
            return error(state,action);
        case COMPLETE_WITHDRAW_START:
            return loading(state,action);
        case COMPLETE_WITHDRAW_SUCCESS:
            return completeWithdraw(state,action);
        case COMPLETE_WITHDRAW_FAIL:
            return error(state,action);
        case ADD_DEPOSIT:
            return addDeposit(state,action);
        case ADD_WITHDRAW:
            return addWithdraw(state,action);
        default:
            return state;
    }
}

export default depositWithdrawReducer;