import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, message, Input, Select } from 'antd';
import { toggleAddPaymentModal } from '../../redux/actions/payment/toggleModal';
import { createPayment } from '../../redux/actions/payment/createPayment';
class AddPaymentModal extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }
    layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };
    onFinish = async (values) => {
        await this.props.onAdd(values);
        this.form.current.resetFields();
        this.props.onClose();
    }
    onFinishFailed = (errors) => {
        console.log(errors)
    }
    handleCancel = () => {
        this.props.onClose();
    }
    render() {

        return (
            <Modal
                title="Add New Payment"
                key={"add-payment-modal"}
                centered
                visible={this.props.visible}
                okButtonProps={{ form: 'add-payment-form', key: 'submit', htmlType: 'submit' }}
                confirmLoading={this.props.loading}
                onCancel={this.handleCancel}
                okText="Save"
                loading={this.props.loading}
            >
                <Form
                    {...this.layout}
                    ref={this.form}
                    style={{ margin: 25 }}
                    id="add-payment-form"
                    name="basic"
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="accountNo"
                        label="AccountNo"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="paymentId"
                        label="Payment Type"
                        rules={
                            [{
                                required: true,
                                message: 'Require payment type',
                            }]
                        }>
                        <Select style={{ width: 180 }}>
                            {
                                this.props.paymentTypes?.map(payment => {
                                    return <Select.Option
                                        key={payment?.id}
                                        value={payment?.id}>
                                        {payment?.name}
                                    </Select.Option>;
                                })
                            }
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    visible: state.payment.addVisible,
    loading: state.payment.loading,
    token: state.auth.token,
    error: state.payment.error,
    message: state.payment.message,
    paymentTypes: state.payment.paymentTypes
})

const mapDispatchToProps = dispatch => ({
    onAdd: (obj, token) => dispatch(createPayment(obj, token)),
    onClose: () => dispatch(toggleAddPaymentModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentModal);