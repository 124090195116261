import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const getWithdraws = () => async dispatch => {
    dispatch({
        type: actionTypes.GET_WITHDRAW_START
    });
    await axios.get('/withdrawals')
        .then(response => {
            dispatch({
                type: actionTypes.GET_WITHDRAW_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => dispatch({
            type: actionTypes.GET_WITHDRAW_FAIL,
            payload: getMessage(error)
        }))
}