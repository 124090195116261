import React from 'react';
import { Row, Col, Table, PageHeader, Button, message } from 'antd';
import { getWithdraws } from '../../redux/actions/deposit-withdraw/getWithdraws';
import { completeWithdraw } from '../../redux/actions/deposit-withdraw/completeWithdraw';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
   CheckOutlined
} from '@ant-design/icons';


class Withdraws extends React.Component {
   async componentDidMount() {
      await this.props.onGetWithdraws();
   }
   componentDidUpdate() {
      if (this.props.message) {
         message.success(this.props.message);
      }
      if (this.props.error) {
         message.error(this.props.error);
      }
   }

   handleComplete = (id) => {
      this.props.onComplete(id);
   }
   columns = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id'
      },
      {
         title: 'Name',
         dataIndex: 'name',
         key: 'name'
      },
      {
         title: 'Amount',
         dataIndex: 'amount',
         key: 'amount'
      },
      {
         title: 'AccountNo',
         key: 'agentPayment',
         render: (text, record) => record?.accountNo
      },
      {
         title: 'Type',
         key: 'payment',
         render: (text, record) => record?.payment?.name
      },
      {
         title: 'Username',
         key: 'username',
         render: (text, record) => <Link to={`/members/${record?.user?.id}`}>
            {record?.user?.username}</Link>
      },
      {
         title: 'Action',
         key: 'action',
         render: (text, record) => <Button
            loading={this.props.loading}
            onClick={() => this.props.onComplete(record?.id)}>
            <CheckOutlined />
         </Button>
      }
   ]

   render() {
      return (
         <>
            <PageHeader title={<h5>Withdrawals</h5>} />
            <Row>
               <Col span={24} style={{
                  width: '100%',
                  overflowX: 'scroll',
                  scrollbarWidth: 'none'
               }}>
                  <Table
                     pagination={false}
                     key="withdraw-table"
                     dataSource={this.props.withdraws}
                     columns={this.columns}
                     loading={this.props.loading} />
               </Col>
            </Row>
         </>
      );
   }
}

const mapStateToProps = state => ({
   withdraws: state.depositWithdraw.withdraws,
   error: state.depositWithdraw.error,
   loading: state.depositWithdraw.loading,
   message: state.depositWithdraw.message,
});

const mapDispatchToProps = dispatch => ({
   onGetWithdraws: () => dispatch(getWithdraws()),
   onComplete: (id) => dispatch(completeWithdraw(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Withdraws);