import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import * as actions from '../redux/actions/auth/auth';
import Login from '../screens/Login';
import Logout from '../screens/Logout';
import App from './App';
import CreateMember from '../components/Member/CreateMember';
import MemberList from '../components/Member/MemberList';
import TabContainer from '../components/Member/TabContainer';
import CollectionReport from '../components/Report/CollectionReport';
import BetDetail from '../components/BetPattern/BetDetail';
import MemberReport from '../components/Report/MemberReport';
import BetReport from '../components/Report/BetReport';
import MainMemberReport from '../components/Report/MainMemberReport';
import ChangePassword from '../components/Account/ChangePassword';
import Dashboard from '../components/Dashboard/Dashboard';
import TransactionDetail from '../components/Dashboard/TransactionDetail';
import MemberTransactionDetail from '../components/Member/MemberTransactionDetail';
import Payment from '../components/Payment/Payment';
import Deposits from '../components/Deposit/Deposits';
import Withdraws from '../components/Withdraw/Withdraws';
import { firebaseMessaging, requestFirebaseNotificationPermission } from '../FirebaseInit';
import { addDeposit } from '../redux/actions/deposit-withdraw/addDeposit';
import { addWithdraw } from '../redux/actions/deposit-withdraw/addWithdraw';
import { notification } from 'antd';
import TwoDDetail from '../components/BetPattern/TwoDDetail';
import ThreeDDetail from '../components/BetPattern/ThreeDDetail';
import TwoDReport from '../components/Report/TwoDReport';
import ThreeDReport from '../components/Report/ThreeDReport';
import FourDReport from '../components/Report/FourDReport';
import FourDDetail from '../components/BetPattern/FourDDetail';
import { getCurrentUser } from '../redux/actions/user/getCurrentUser';

class ConfigRoute extends React.Component {
      async componentDidMount() {
            await this.props.onAuthStateCheck();
            await this.props.onGetCurrentUser();
            if (firebaseMessaging) {
                  requestFirebaseNotificationPermission()
                        .then((firebaseToken) => {
                              // eslint-disable-next-line no-console
                              // console.log('token is',[firebaseToken]);
                              localStorage.setItem('agentFCMToken', firebaseToken);
                        })
                        .catch((err) => {
                              return err;
                        });
                  firebaseMessaging.onMessage(payload => {
                        console.log('onMessage invoked')
                        this.checkType(payload?.data);
                        this.openNotificationWithIcon({
                              title: payload?.notification.title,
                              body: payload?.notification.body,
                        })
                  });
            }
      }
      checkType = (data) => {
            if (data?.type === "deposits") {
                  this.addDeposit(data);
            }
            if (data?.type === "withdrawals") {
                  this.addWithdraw(data);
            }
      }
      openNotificationWithIcon = ({ title, body }) => {
            notification.info({
                  key: 'updatable',
                  message: title,
                  description: body
            });
      };
      addDeposit = async value => {
            const deposit = {
                  id: value?.depositId,
                  amount: value?.amount,
                  imageUrl: value?.imageUrl,
                  agentPayment: {
                        accountNo: value?.accountNo,
                        payment: {
                              name: value?.paymentName
                        }
                  },
                  user: {
                        id: value?.userId,
                        username: value?.username
                  }
            }
            await this.props.onAddDeposit(deposit);
      }
      addWithdraw = value => {
            const withdraw = {
                  id: value?.withdrawalId,
                  name: value?.name,
                  amount: value?.amount,
                  accountNo: value?.accountNo,
                  user: {
                        id: value?.userId,
                        username: value?.username
                  },
                  payment: {
                        name: value?.paymentName
                  }
            };
            this.props.onAddWithdraw(withdraw);
      }
      render() {
            return (<>
                  <Switch>
                        <PrivateRoute exact path="/">
                              <App>
                                    <Dashboard />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/transactions/:date">
                              <App>
                                    <TransactionDetail />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/members/new">
                              <App>
                                    <CreateMember />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute exact path="/members">
                              <App>
                                    <MemberList />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute exact path="/members/:id">
                              <App>
                                    <TabContainer />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute exact path="/members/:id/:date">
                              <App>
                                    <MemberTransactionDetail />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/collection_report">
                              <App>
                                    <CollectionReport />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute exact path="/report/daily/:date/agents/:agentName/members">
                              <App>
                                    <MemberReport />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute exact path="/report/daily/:date/members/:memberName/bets">
                              <App>
                                    <BetReport />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/member_report">
                              <App>
                                    <MainMemberReport />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/bet_detail/:id">
                              <App>
                                    <BetDetail />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/account">
                              <App>
                                    <ChangePassword />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/payments">
                              <App>
                                    <Payment />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/deposits">
                              <App>
                                    <Deposits />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/withdraws">
                              <App>
                                    <Withdraws />
                              </App>
                        </PrivateRoute>
                        {this.props.currentUser ? ([
                              <PrivateRoute path="/2d_report">
                                    <App>
                                          <TwoDReport />
                                    </App>
                              </PrivateRoute>,
                              <PrivateRoute path="/3d_report">
                                    <App>
                                          <ThreeDReport />
                                    </App>
                              </PrivateRoute>,
                              <PrivateRoute path="/4d_report">
                                    <App>
                                          <FourDReport />
                                    </App>
                              </PrivateRoute>,
                              <PrivateRoute path="/2d_detail/:id">
                                    <App>
                                          <TwoDDetail />
                                    </App>
                              </PrivateRoute>,
                              <PrivateRoute path="/3d_detail/:id">
                                    <App>
                                          <ThreeDDetail />
                                    </App>
                              </PrivateRoute>,
                              <PrivateRoute path="/4d_detail/:id">
                              <App>
                                    <FourDDetail />
                              </App>
                        </PrivateRoute>
                        ]) : null}
                        <Route path="/login">
                              <Login />
                        </Route>
                        <Route path="/logout">
                              <Logout />
                        </Route>
                  </Switch>
            </>);
      }
}

const mapStateToProps = state => ({
      currentUser: state.user.current
})

const mapDispatchToProps = dispatch => ({
      onAuthStateCheck: () => dispatch(actions.authCheckState()),
      onAddDeposit: (deposit) => dispatch(addDeposit(deposit)),
      onAddWithdraw: (withdraw) => dispatch(addWithdraw(withdraw)),
      onGetCurrentUser: () => dispatch(getCurrentUser()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfigRoute));