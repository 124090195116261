import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const updatePayment=({
    paymentId,
    name,
    accountNo,
    id
})=>async dispatch=>{
    dispatch({
        type:actionTypes.UPDATE_PAYMENT_START
    });
    const options={
        url:`/agentPayments/${id}`,
        method:'PUT',
        data:{
            paymentId,
            name,
            accountNo
        }
    }
    await axios(options)
            .then(response=>{
                dispatch({
                type:actionTypes.UPDATE_PAYMENT_SUCCESS,
                payload:response.data
            });
        })
            .catch(error=>dispatch({
                type:actionTypes.UPDATE_PAYMENT_FAIL,
                payload:getMessage(error)
            }))
}