import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, message, InputNumber, Row, Col } from 'antd';
import * as actionTypes from '../../redux/actions/member/actionTypes';
import { updateBetLimit } from '../../redux/actions/member/updateMember';

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
};

class UpdateBetLimitationModal extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }
    onFinish = async (values) => {
        await this.props.onUpdate({
            id: this.props.member?.id,
            limitId: this.props.member?.betLimitation?.id,
            maxForSingleBet: values.maxForSingleBet,
            maxForMixBet: values.maxForMixBet,
        });
        if (this.props.error) {
            message.error(this.props.error);
        }
        if (this.props.message) {
            message.success(this.props.message);
        }
        this.form.current.resetFields();
        this.props.onClose();
    }
    onFinishFailed = (errors) => {
        console.log(errors)
    }
    handleCancel = () => {
        this.props.onClose();
    }
    render() {
        return (
            <Modal
                width={800}
                title="Update Bet Limitation"
                key={this.props.member?.id}
                centered
                visible={this.props.visible}
                okButtonProps={{ form: 'update-bl-form', key: 'submit', htmlType: 'submit' }}
                confirmLoading={this.props.loading}
                onCancel={this.handleCancel}
                okText="Save"
                loading={this.props.loading}
            >
                <Form
                    {...layout}
                    ref={this.form}
                    style={{ margin: 25 }}
                    id="update-bl-form"
                    name="basic"
                    initialValues={{
                        maxForSingleBet: this.props.member?.betLimitation?.maxForSingleBet,
                        maxForMixBet: this.props.member?.betLimitation?.maxForMixBet,
                    }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        name="maxForMixBet"
                        label="Max For Mix Bet"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber style={{ width: 150 }} />
                    </Form.Item>
                    <Form.Item
                        name="maxForSingleBet"
                        label="Max For Single Bet"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber style={{ width: 150 }} />
                    </Form.Item>

                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    visible: state.member.updateBetLimitVisible,
    loading: state.member.loading,
    error: state.member.error,
    message: state.member.message,
    member: state.member.one
})

const mapDispatchToProps = dispatch => ({
    onUpdate: (obj) => dispatch(updateBetLimit(obj)),
    onClose: () => dispatch({
        type: actionTypes.TOGGLE_BET_LIMIT_UPDATE
    }),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateBetLimitationModal);