import React from 'react';
import { Table } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
    EyeOutlined,
} from '@ant-design/icons'

export default function TransactionTable({ data }) {
    const history = useHistory();
    const columns = [
        {
            title: 'Date',
            dataIndex: 'transactionDate',
            key: 'transactionDate',
            render: (text, record) => moment(record.transactionDate).format('YYYY-MM-DD')
        },
        {
            title: 'Transfer In',
            dataIndex: 'transferInAmount',
            key: 'transferInAmount',
            align: 'right',
            render: (text, record) => `${record.transferInAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        },
        {
            title: 'Transfer Out',
            dataIndex: 'transferOutAmount',
            key: 'transferOutAmount',
            align: 'right',
            render: (text, record) => `${record.transferOutAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        },
        {
            title: 'Commission Amount',
            dataIndex: 'commissionAmount',
            key: 'commissionAmount',
            align: 'right',
            render: (text, record) => `${record.commissionAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        },
        {
            title: 'Balance',
            dataIndex: 'closingBalance',
            key: 'closingBalance',
            align: 'right',
            render: (text, record) => `${record.closingBalance}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (text, record) => <EyeOutlined onClick={
                () => history.push(`/transactions/${record.transactionDate}`)} />
        },
    ]
    return <Table columns={columns} dataSource={data} />
}