export const CREATE_MEMBER_START='CREATE_MEMBER_START';
export const CREATE_MEMBER_SUCCESS='CREATE_MEMBER_SUCCESS';
export const CREATE_MEMBER_FAIL='CREATE_MEMBER_FAIL';

export const GET_MEMBERS_START='GET_MEMBER_START';
export const GET_MEMBERS_SUCCESS='GET_MEMBER_SUCCESS';
export const GET_MEMBERS_FAIL='GET_MEMBER_FAIL';

export const UPDATE_MEMBER_START='UPDATE_MEMBER_START';
export const UPDATE_MEMBER_SUCCESS='UPDATE_MEMBER_SUCCESS';
export const UPDATE_MEMBER_FAIL='UPDATE_MEMBER_FAIL';

export const SET_COMMISSION_TO_UPDATE='SET_COMMISSION_TO_UPDATE';

export const TOGGLE_BASIC_INFO_UPDATE='TOGGLE_BASIC_INFO_UPDATE';
export const TOGGLE_BET_LIMIT_UPDATE='TOGGLE_BET_LIMIT_UPDATE';
export const TOGGLE_COMMISSION_UPDATE='TOGGLE_COMMISSION_UPDATE';
export const TOGGLE_SINGLE_BET_COMMISSION_UPDATE='TOGGLE_SINGLE_BET_COMMISSION_UPDATE';
export const TOGGLE_SINGLE_BET_UPDATE='TOGGLE_SINGLE_BET_UPDATE';
export const TOGGLE_EXTRA_COMMISSION_UPDATE='TOGGLE_EXTRA_COMMISSION_UPDATE';
export const TOGGLE_2D_COMMISSION_UPDATE='TOGGLE_2D_COMMISSION_UPDATE';
export const TOGGLE_3D_COMMISSION_UPDATE='TOGGLE_3D_COMMISSION_UPDATE';
export const TOGGLE_4D_COMMISSION_UPDATE='TOGGLE_4D_COMMISSION_UPDATE';

export const GET_ONE_MEMBER_START='GET_ONE_MEMBER_START';
export const GET_ONE_MEMBER_SUCCESS='GET_ONE_MEMBER_SUCCESS';
export const GET_ONE_MEMBER_FAIL='GET_ONE_MEMBER_FAIL';

export const UPDATE_SUSPEND_START='UPDATE_SUSPEND_START';
export const UPDATE_SUSPEND_SUCCESS='UPDATE_SUSPEND_SUCCESS';
export const UPDATE_SUSPEND_FAIL='UPDATE_SUSPEND_FAIL';

export const RESET_PASSWORD_START='RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS='RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL='RESET_PASSWORD_FAIL';
export const TOGGLE_RESET_PASSWORD_MODAL='TOGGLE_RESET_PASSWORD_MODAL';

export const UPDATE_MEMBER_BALANCE='UPDATE_MEMBER_BALANCE';


